#calendar {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color:peru;
  }

  h3 {
    color:rgb(83, 213, 213);
  }
}

#event {
  display:flex;
  flex-direction: column;
  align-items: space-around;
  margin-bottom: 1em;
  padding:20px 0px;
  max-width:200px;
  p {
    font-size: 18;
    margin: 0;
  }

  p#date {
    color:cornflowerblue;
  }
p#summary {
  color:moccasin;
}
p#location {
  color:indianred;
}
}
