body {
  background-color: #282c34;
}

a {
  color:lightyellow
}

h1, h2, h3, h4, h5 {
  font-family: 'Lora';
}



.text_button {
  font-style: italic;
  display:inline;
}

.text_button:hover {
  cursor: pointer;
}


#app_wrap {
  display: flex;
  // font-family: 'Crimson Pro', serif;
  font-family: 'Open Sans', sans-serif;
  color: white;
  max-width: 1200px;
  padding-left:1em;
  padding-right:1em;
  margin-left: auto;
  margin-right: auto;
}

#left_side_column {
  align-items: center;
  text-align: center;
  margin:auto;
  display: flex;
  max-width: 400px;
  flex-direction: column;
}

#main-app-heading {
  // min-width: 300px;
  h1 {
    color:rgb(69, 237, 142);
    border: rgb(116, 30, 44) thick solid;
    font-size: 5em;
  }

}


#truncated_bio_wrap {
  text-align: left;
  // max-width:300px;
  overflow: hidden;
}

.animated_height {
  transition: max-height 1s ease-out;

}

.truncated {
  max-height: 300px;
  margin-top: 20px;
}

.expanded {
  max-height: 2000px;
}

#right_side_column {
  display: flex;
  flex-direction: column;
  padding: 3em 2em 2em 2em;
  gap: 4em
}
img {
  width: 100%;
}

@media (min-width: 0px) {
  #main-app-heading {
    h1 {
      min-width:280px;
    }
    
  }
}

@media (min-width: 1200px) {
  // #main-app-heading {
  //   h1 {
  //     width: 100px;
  //     min-width:300px;
  //   }
    
  // }
}
